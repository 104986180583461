import { initSearch } from './search';

$(document).on('turbolinks:load', function () {
  const CLASS_NAMES = {
    HEADER_BUTTONS: 'mobile-menu__buttons',
    SEARCH_CONTAINER: 'n-header__search',
    NAV_CONTENT: 'n-header-navbar__nav',
  };
  const navbarId = 'mobile-menu-content';
  const dropdownId = 'navbar-search-suggestions-mobile';
  const headerButtonSelector = `#${navbarId} .${CLASS_NAMES.HEADER_BUTTONS}`;
  const searchContainerSelector = `#${navbarId} .${CLASS_NAMES.SEARCH_CONTAINER}`;
  const navContentSelector = `#${navbarId} .${CLASS_NAMES.NAV_CONTENT}`;
  const backBtnSelector = '#mobile-menu-back-btn';

  const onClose = () => {
    $(searchContainerSelector).css({
      '-webkit-transform': 'translate(0,0)',
      '-ms-transform': 'translate(0,0)',
      transform: 'translate(0,0)',
      transition: 'unset',
    });

    $(navContentSelector).css({
      display: 'block',
    });
    $(headerButtonSelector).css({ visibility: 'visible' });
    $(backBtnSelector).prop('disabled', true);
  };

  const onOpen = () => {
    $(searchContainerSelector).css({
      transition: 'transform 0.3s ease',
    });
    $(backBtnSelector).prop('disabled', false);
    $(navContentSelector).css({
      display: 'none',
    });

    $(searchContainerSelector).css({
      '-webkit-transform': 'translate(0,-70px)',
      '-ms-transform': 'translate(0,-70px)',
      transform: 'translate(0,-70px)',
    });
    $(headerButtonSelector).css({ visibility: 'hidden' });
  };

  initSearch({
    navbarId,
    searchInputId: 'header-search-mobile',
    dropdownId,
    onClose,
    onOpen,
  });
});
