export const ResizePlugin = (slider) => {
  const observer = new ResizeObserver(function () {
    slider.update();
  });

  slider.on("created", () => {
    observer.observe(slider.container);
  });
  slider.on("destroyed", () => {
    observer.unobserve(slider.container);
  });
};

export const AutoPlayPlugin = (slider) => {
  let timeout;
  let mouseOver = false;

  function clearNextTimeout() {
    clearTimeout(timeout);
  }

  function nextTimeout() {
    clearTimeout(timeout);

    const { options, slides } = slider;
    const { perView } = options.slides;
    const { abs } = slider.track?.details || {};

    const currentStep = abs || 0;

    if (mouseOver || slides.length <= perView) return;
    timeout = setTimeout(() => {
      slider.moveToIdx(currentStep + 1, true, 700);
    }, 5000);
  }

  function handleOnMouseOver() {
    mouseOver = true;
    clearNextTimeout();
  }

  function handleOnMouseOut() {
    mouseOver = false;
    nextTimeout();
  }

  function handleAnimationEnded(slider) {
    nextTimeout();

    const { rel, progress } = slider.track.details;
    if (rel === 0 && progress !== 0) {
      slider.moveToIdx(0, true, { duration: 0 });
    }
  }

  slider.on("created", () => {
    slider.container.addEventListener("mouseover", handleOnMouseOver);
    slider.container.addEventListener("mouseout", handleOnMouseOut);
    nextTimeout();
  });

  slider.on("dragStarted", clearNextTimeout);
  slider.on("animationEnded", handleAnimationEnded);
  slider.on("updated", nextTimeout);
  slider.on("destroyed", () => {
    clearNextTimeout();
    slider.container.removeEventListener("mouseover", handleOnMouseOver);
    slider.container.removeEventListener("mouseout", handleOnMouseOut);
  });
};

export const NavigationPlugin = (slider) => {
  const wrapperClassName = `keen-navigation-wrapper--${slider.container.id}`;
  const wrapperClassNameFull = `keen-navigation-wrapper ${wrapperClassName}`;
  let wrapper, arrowLeft, arrowRight;

  function markup(remove) {
    wrapperMarkup(remove);
    arrowMarkup(remove);
  }

  function removeElement(elment) {
    elment.parentNode.removeChild(elment);
  }
  function createDiv(className) {
    const div = document.createElement("div");
    const classNames = className.split(" ");
    classNames.forEach((name) => div.classList.add(name));
    return div;
  }

  function handleOnLeftClick(slider, ev) {
    const { perView } = slider.options.slides;
    const { abs } = slider.track?.details || {};

    const currentStep = abs || 0;
    const nextStep = currentStep - perView;
    slider.moveToIdx(nextStep, true, 700);

    ev.preventDefault();
    ev.stopPropagation();
  }

  function handleOnRightClick(slider, ev) {
    const { perView } = slider.options.slides;
    const { abs } = slider.track?.details || {};

    const currentStep = abs || 0;
    const nextStep = currentStep + perView;
    slider.moveToIdx(nextStep, true, 700);

    ev.preventDefault();
    ev.stopPropagation();
  }

  function arrowMarkup(remove) {
    if (remove) {
      removeElement(arrowLeft);
      removeElement(arrowRight);
      return;
    }
    arrowLeft = createDiv("arrow arrow--left");
    arrowLeft.addEventListener("click", (ev) => handleOnLeftClick(slider, ev));
    arrowRight = createDiv("arrow arrow--right");
    arrowRight.addEventListener("click", (ev) => handleOnRightClick(slider, ev));

    wrapper.appendChild(arrowLeft);
    wrapper.appendChild(arrowRight);
  }

  function wrapperMarkup(remove) {
    if (remove) {
      const parent = wrapper.parentNode;
      while (wrapper.firstChild) {
        parent.insertBefore(wrapper.firstChild, wrapper);
      }
      removeElement(wrapper);
      return;
    }

    wrapper = createDiv(wrapperClassNameFull);
    slider.container.parentNode.appendChild(wrapper);
    wrapper.appendChild(slider.container);
  }

  function handleNavigationUpdate(newSlider) {
    const BREAKPOINT_SIZE = 810;
    const windowInnerWidth = window.innerWidth;
    const { options, slides } = newSlider;
    const { perView } = options.slides;

    const isNavigationAdded = !!document.querySelector(`.${wrapperClassName}`);

    if (
      windowInnerWidth < BREAKPOINT_SIZE &&
      isNavigationAdded &&
      options.loop
    ) {
      markup(true);
      slider.update({
        ...options,
        loop: false,
        slides: {
          perView: "auto",
          spacing: 0,
        },
      });
      return;
    }

    if (
      slides.length > perView &&
      windowInnerWidth >= BREAKPOINT_SIZE &&
      !options.loop &&
      !isNavigationAdded
    ) {
      markup();
      slider.update({
        ...options,
        loop: true,
      });
    }
  }

  const observer = new ResizeObserver(function () {
    slider.update();
  });

  slider.on("updated", (updatedSlider) => {
    handleNavigationUpdate(updatedSlider);
  });

  slider.on("created", () => {
    handleNavigationUpdate(slider);
    observer.observe(slider.container);
  });

  slider.on("destroyed", () => {
    observer.unobserve(slider.container);
    markup(true);
  });
};
