import KeenSlider from "keen-slider";
import { AutoPlayPlugin } from "../../js/utils/swiper-plugins";

const exploreMenuBtnId = "header-explore-menu-btn";
const exploreMenuBtnQuery = `#${exploreMenuBtnId}`;
const exploreContainerQuery = "#header-explore-dropdown";
let exploreMenuOpenTimeout = null;

function closeDropdown(e, force) {
  const headerNavbar = document.querySelector(".n-header");

  if (e && (e.target.id == exploreMenuBtnId || !headerNavbar) && !force) {
    if (exploreMenuOpenTimeout && e.target.id !== exploreMenuBtnId) {
      clearTimeout(exploreMenuOpenTimeout);
    }

    return false;
  }

  if (exploreMenuOpenTimeout) {
    clearTimeout(exploreMenuOpenTimeout);
  }

  if (!window.IS_EXPLORE_MENU_OPEN) {
    return;
  }

  if (
    !force &&
    e &&
    $.contains(headerNavbar, e.target) &&
    !(
      $(e.target).hasClass("n-header-navbar__link") ||
      $(e.target).hasClass("n-header-navbar__logo") ||
      e.target.id === "header-search"
    )
  ) {
    return false;
  }

  $(".modal-overlay").removeClass("modal-overlay--showed");
  $(exploreMenuBtnQuery).removeClass("is-open");
  $(exploreContainerQuery).animate({
    opacity: 0,
    maxHeight: 0,
  });
  window.restoreAppScroll();
  $("#header-events-carousel").css({
    visibility: 'hidden',
  });

  window.IS_EXPLORE_MENU_OPEN = false;
}

function forceCloseDropdown() {
  $(".modal-overlay").removeClass("modal-overlay--showed");
  $(exploreMenuBtnQuery).removeClass("is-open");
  $(exploreContainerQuery).css({
    opacity: 0,
    maxHeight: 0,
  });
  $("#header-events-carousel").css({
    visibility: 'hidden',
  });
  window.restoreAppScroll();

  window.IS_EXPLORE_MENU_OPEN = false;
}

$(document).on("turbolinks:load", function () {
  const HEADER_CAROUSEL_ID = "#header-events-carousel";

  const SEARCH_DOM_CLASSES = {
    SEARCH_CONTAINER: "n-header__search",
  };

  const navbarId = "header-navbar";
  const dropdownId = "navbar-search-suggestions";
  const isCarouselExists = $("#header-events-carousel")?.length;

  if (!isCarouselExists) preloadEventsData();

  $(exploreMenuBtnQuery).on("mouseenter", function () {
    setTimeout(createHeaderEventsCarousel, 1000);

    exploreMenuOpenTimeout = setTimeout(openDropdown, 500);
  });

  $(document).on("mousemove", function (e) {
    closeDropdown(e);
  });

  $(document).on("click", function (e) {
    closeDropdown(e);
  });

  function openDropdown() {
    window.IS_EXPLORE_MENU_OPEN = true;

    const searchInput = $("#header-search");
    if (searchInput.is(":focus")) {
      searchInput.trigger("blur");
    } else {
      window.preventAppScroll();
    }

    $(".modal-overlay").addClass("modal-overlay--showed");
    $(exploreMenuBtnQuery).addClass("is-open");
    $(exploreContainerQuery).animate({
      opacity: 1,
      maxHeight: "426px",
    });
    $("#header-events-carousel").css({
      visibility: 'visible',
    });

    $(`#${navbarId} .${SEARCH_DOM_CLASSES.SEARCH_CONTAINER}`).removeClass(
      `${SEARCH_DOM_CLASSES.SEARCH_CONTAINER}--active`
    );
    $(`#${dropdownId}`).css({
      opacity: 0,
      maxHeight: 0,
    });

    callGTMEvent("openExplore");
  }

  const createEventsBlock = (data) => {
    const slidesData = data
      .map((el) => {
        return `
        <div class="keen-slider__slide">
          <a href="/events/${el.slug}" class="event-card">
            ${el.image_link ? '<img src="' + el.image_link + '" alt="' + el.title + '" class="event-card-image" />' : '<div class="event-card-image"></div>'}
            <div class="event-card-content">
              <div class="event-card-title">${el.title}</div>
              <div class="event-card-subtitle">${el.venue_name || " "}</div>
              <div class="event-card-date">${el.day_month_year_full}</div>
              <div class="event-card-location">${el.city_and_state || " "}</div>
            </div>
          </a>
        </div>`;
      })
      .join("");

    return `
      <div class="explore-dropdown-col__header mb-0">
        <div class="explore-dropdown-col__title">Upcoming events</div>
      </div>
      <div class="explore-dropdown-col__body">
        <div id="header-events-carousel" class="keen-slider" style="visibility: hidden;">
          ${slidesData}
        </div>
      </div>
  `;
  };

  function createHeaderEventsCarousel() {
    const carouselEl = document.getElementById("header-events-carousel");
    const isCarouselAdded = carouselEl && "keenSliderV" in carouselEl?.dataset;

    if (
      (window.HEADER_UPCOMING_EVENT_QUERY &&
        JSON.parse(window.HEADER_UPCOMING_EVENT_QUERY).length < 3) ||
      isCarouselAdded
    )
      return;

    if ($(HEADER_CAROUSEL_ID).length) {
      new KeenSlider(
        HEADER_CAROUSEL_ID,
        {
          vertical: true,
          loop: true,
          drag: false,
          slides: {
            perView: 2,
            spacing: 24,
          },
        },
        [AutoPlayPlugin]
      );
    }
  }

  function preloadEventsData() {
    const events_url = window.location.origin + "/events/events_filled";
    const exploreDropdownId = "header-explore-dropdown";

    function insertUpcomingEventsBlock(data) {
      $(`#${exploreDropdownId} .explore-dropdown__secondary-col`).html(
        createEventsBlock(data)
      );

      $(`${HEADER_CAROUSEL_ID} .keen-slider__slide .event-card`).on(
        "click",
        function (event) {
          dataLayer.push({
            event: "openEventFromUpcoming",
            event_link: event.currentTarget.href,
          });
        }
      );
    }

    $.ajax({
      url: events_url,
      type: "GET",
      dataType: "json",
      data: { is_featured: true, size: 7 },
      statusCode: {
        200: function (data) {
          if (data?.length) {
            insertUpcomingEventsBlock(data);
            sessionStorage.setItem(
              "HEADER_UPCOMING_EVENT_QUERY",
              JSON.stringify(data)
            );
          } else {
            // HEADER_UPCOMING_EVENT_QUERY = data;
          }
        },
      },
    });
  }
});

$(document).on("turbolinks:before-cache", function () {
  forceCloseDropdown();
});
