import { initSearch } from './search';

$(document).on('turbolinks:load', function () {
  const onClose = (e) => {
    if ($('#header-explore-dropdown').hasClass('is-open')) {
      return;
    }

    $('.modal-overlay').removeClass('modal-overlay--showed');

    if (e && e.target.tagName !== 'BUTTON') {
      $('.modal-overlay').removeClass('modal-overlay--showed');
    }

    window.restoreAppScroll();
  };

  const onOpen = () => {
    $('.modal-overlay').addClass('modal-overlay--showed');
    window.preventAppScroll();
  };

  initSearch({
    onClose,
    onOpen,
  });
});
