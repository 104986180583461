const mobileMenuContainerQuery = "#mobile-menu-content";
const mobileMenuBackBtnQuery = "#mobile-menu-back-btn";
const mobileMenuHeaderButtonsElQuery = ".mobile-menu__buttons";
const mobileMenuContentBodyElQuery = ".mobile-menu__content";

const exploreContainerQueryMobile = "#header-explore-dropdown";

function closeExploreMenu() {
  window.IS_EXPLORE_MENU_OPEN = false;
  $(exploreContainerQueryMobile).animate({
    opacity: 0,
    maxHeight: 0,
  });
  $(mobileMenuBackBtnQuery).prop("disabled", true);
  $(mobileMenuContentBodyElQuery).animate({ opacity: 1 });
  $(mobileMenuHeaderButtonsElQuery).css({ display: "grid" });
}

function closeMobileMenu() {
  function closeContainer() {
    $(mobileMenuContainerQuery).animate({
      top: "100%",
    });
    window.restoreAppScroll();
  }

  if (window.IS_EXPLORE_MENU_OPEN) {
    closeExploreMenu();
  }

  closeContainer();
}

$(document).on("turbolinks:load", function () {
  $("#mobile-menu-btn").on("click", () => {
    $(mobileMenuContainerQuery).animate({
      top: 0,
    });
    window.preventAppScroll();

    callGTMEvent("openExplore");
  });

  $("#mobile-menu-close-btn").on("click", () => {
    closeMobileMenu();
  });

  $("#header-explore-menu-btn-mobile").on("click", function () {
    window.IS_EXPLORE_MENU_OPEN = true;

    $(mobileMenuHeaderButtonsElQuery).css({ display: "none" });
    $(mobileMenuBackBtnQuery).prop("disabled", false);
    $(mobileMenuContentBodyElQuery).css({ opacity: 0 });
    $(exploreContainerQueryMobile).animate({
      opacity: 1,
      maxHeight: "100%",
    });
  });

  $(mobileMenuBackBtnQuery).on("click", function (e) {
    closeExploreMenu();
  });
});

$(document).on("turbolinks:before-cache", function () {
  if (window.IS_EXPLORE_MENU_OPEN) {
    window.IS_EXPLORE_MENU_OPEN = false;
    $(exploreContainerQueryMobile).css({
      opacity: 0,
      maxHeight: 0,
    });
    $(mobileMenuBackBtnQuery).prop("disabled", true);
    $(mobileMenuContentBodyElQuery).css({ opacity: 1 });
    $(mobileMenuHeaderButtonsElQuery).css({ display: "grid" });
  }

  $(mobileMenuContainerQuery).css({
    top: "100%",
  });
  window.restoreAppScroll();
});
