import { insertSearchCarouselData } from './create-search-list';
require('./mobile-search');
require('./desktop-search');

$(document).on('turbolinks:load', function () {
  const search_url = window.location.origin + '/search/search_default.json';

  if (window.DEFAULT_SEARCH_RESULTS) {
    insertSearchCarouselData(JSON.parse(window.DEFAULT_SEARCH_RESULTS));
    return;
  }

  $.ajax({
    url: search_url,
    type: 'GET',
    dataType: 'json',
    statusCode: {
      200: function (data) {
        if (data) {
          sessionStorage.setItem('DEFAULT_SEARCH_RESULTS', JSON.stringify(data));
          window.DEFAULT_SEARCH_RESULTS = JSON.stringify(data);
          insertSearchCarouselData(data);
        } else {
          window.DEFAULT_SEARCH_RESULTS = data;
        }
      },
    },
  });
});
