import KeenSlider from 'keen-slider';
import { ResizePlugin } from '../../js/utils/swiper-plugins';

$(document).on('turbolinks:load', function () {
  if ($('#hero-cards-carousel').length) {
    new KeenSlider(
      '#hero-cards-carousel',
      {
        rubberband: false,
        mode: 'free',
        slides: { perView: 'auto' },
        breakpoints: {
          '(min-width: 810px)': {
            rubberband: false,
            slides: { perView: 2, spacing: 24 },
          },
          '(min-width: 1200px)': {
            rubberband: false,
            slides: { perView: 4, spacing: 24 },
          },
        },
      },
      [ResizePlugin],
    );
  }

  if ($('#nightlife-stories-carousel').length) {
    new KeenSlider(
      '#nightlife-stories-carousel',
      {
        rubberband: false,
        mode: 'free-snap',
        slides: { perView: 'auto' },
        breakpoints: {
          '(min-width: 810px)': {
            rubberband: false,
            slides: { perView: 3, spacing: 24 },
          },
        },
      },
      [ResizePlugin],
    );
  }

  if ($('#how-membership-works-carousel').length) {
    new KeenSlider(
      '#how-membership-works-carousel',
      {
        rubberband: false,
        mode: 'free',
        slides: { perView: 'auto' },
        breakpoints: {
          '(min-width: 810px)': {
            rubberband: false,
            slides: { perView: 3, spacing: 24 },
          },
        },
      },
      [ResizePlugin],
    );
  }
});
