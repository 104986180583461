import { highlightQuery } from '../../../js/utils/highlight_query';

const SEARCH_GTM_TYPES = {
  NEARBY: 'nearby',
  POPULAR_DESTINATION: 'popular_destination',
  TRENDING_VENUE: 'trending_venue',
  VENUE: 'venue',
  DESTINATION: 'destination',
};

const SEARCH_GTM_EVENTS = {
  [SEARCH_GTM_TYPES.NEARBY]: 'openNearbyFromHeader',
  [SEARCH_GTM_TYPES.POPULAR_DESTINATION]: 'openPopularDestinationFromHeader',
  [SEARCH_GTM_TYPES.TRENDING_VENUE]: 'openTrendingVenueFromHeader',
  [SEARCH_GTM_TYPES.VENUE]: 'openVenueFromSearch',
  [SEARCH_GTM_TYPES.DESTINATION]: 'openDestinationFromSearch',
};

const createSearchListHtml = (data, searchTerm) => {
  const { venues = [], destinations = [], nearby } = data || {};
  const isDefaultSearchResults = !searchTerm || searchTerm?.length < 2;

  if (!venues?.length && !destinations?.length) {
    return `<div class="search-suggestions__not-found">
          <b>No results found</b>
          <p>
            If a venue is not listed on our website, we can still make bookings on your behalf. Contact our concierge
            team, and we'll arrange everything.
          </p>
          <button class="n-btn n-btn-outline" data-gtm-type="search-not-found" onclick="openContactModal('search-not-found')">
            Contact concierge
          </button>
        </div>`;
  }

  const res = [];

  if (isDefaultSearchResults && nearby) {
    res.push('<li class="search-suggestions__label search-suggestions__label--nearby">Nearby you</li>');
    res.push(`<li class="search-suggestions__item">
                    <a href="${nearby.link}" data-gtm-type="${SEARCH_GTM_TYPES.NEARBY}">
                      ${nearby.city_name || ''}${nearby.country_name ? ', ' + nearby.country_name : ''}
                    </a>
                  </li>`);
  }

  if (destinations.length) {
    const destinationsTitle = isDefaultSearchResults ? 'Popular destinations' : 'Destinations';
    const destinationGtmType = isDefaultSearchResults
      ? SEARCH_GTM_TYPES.POPULAR_DESTINATION
      : SEARCH_GTM_TYPES.DESTINATION;
    res.push(`<li class="search-suggestions__label search-suggestions__label--popular">${destinationsTitle}</li>`);

    destinations.forEach((destination) => {
      res.push(`
            <li class="search-suggestions__item">
              <a href="${destination.link}" data-gtm-type="${destinationGtmType}">
                ${highlightQuery(`${destination.name || ''}${destination.country_name ? ', ' + destination.country_name : ''}`, searchTerm)}
              </a>
            </li>`);
    });
  }

  if (venues.length) {
    const venueTitle = isDefaultSearchResults ? 'Trending venues' : 'Venues';
    const venueGtmType = isDefaultSearchResults ? SEARCH_GTM_TYPES.TRENDING_VENUE : SEARCH_GTM_TYPES.VENUE;
    res.push(`<li class="search-suggestions__label search-suggestions__label--trending">${venueTitle}</li>`);

    venues.forEach((venue) => {
      const countryAndCityEl = isDefaultSearchResults
        ? ''
        : `<i>${highlightQuery(`${venue.city_name || ''}${venue.country_name ? ', ' + venue.country_name : ''}`, searchTerm)}</i>`;

      res.push(`
            <li class="search-suggestions__item">
              <a href="${venue.link}" data-gtm-type="${venueGtmType}">
                ${highlightQuery(venue.name, searchTerm)}${countryAndCityEl}
              </a>
            </li>`);
    });
  }

  return res.join('');
};

export function insertSearchCarouselData(data, searchTerm) {
  $('.search-suggestions-container ul').html(createSearchListHtml(data, searchTerm));
  $('.search-suggestions__item a').on('click', function (event) {
    dataLayer.push({
      event: SEARCH_GTM_EVENTS[event.currentTarget.dataset.gtmType],
      event_link: event.currentTarget.href,
    });
  });
}
