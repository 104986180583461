export const highlightQuery = (string, searchQuery) => {
  if (!searchQuery || searchQuery?.length < 2) {
    return string;
  }

  if (!string) {
    return "";
  }

  let expr = searchQuery;
  expr = expr.replace(/\s+/, "|", searchQuery);
  const regex = new RegExp(expr, "gi");
  return string.replace(regex, function ($1) {
    return "<b>" + $1 + "</b>";
  });
};
