import { isInViewport } from '../../js/utils/is-in-viewport';

$(window).on('scroll', function () {
  const scrolled = $(window).scrollTop();

  $('.parallax').each(function () {
    const initY = $(this).offset().top;
    const height = $(this).height();

    const visible = isInViewport(this);
    if (visible) {
      const diff = scrolled - initY;
      const ratio = Math.round((diff / height) * 100);
      const fixedRatio = ratio < 0 ? ratio : 0;

      if (window.innerWidth > 2000) {
        $(this).css('background-position', 'center ' + parseInt(-(fixedRatio * 0.2)) + '%');
      } else {
        $(this).css('background-position', 'center ' + parseInt(-(fixedRatio * 0.5)) + '%');
      }
    }
  });
});
