import { OverlayScrollbars } from "overlayscrollbars";
import { isInViewport } from "../../js/utils/is-in-viewport";

function handleParallax() {
  $(".parallax").each(function () {
    const initY = $(this).offset().top;
    const height = $(this).height();

    const visible = isInViewport(this);
    if (visible) {
      const ratio = Math.round(((initY - height) / height) * 100);
      const formedRatio = -ratio * 0.5;

      const fixedRatio =
        formedRatio < 0 ? 0 : formedRatio > 100 ? 100 : formedRatio;

      $(this).css(
        "background-position",
        "center " + parseInt(fixedRatio) + "%"
      );
    }
  });
}

const getPercantage = (value, total) => {
  return (value / total) * 100;
};

const handleHeaderClass = (osInstance) => {
  if (!osInstance) return;

  const { viewport } = osInstance.elements();
  const { scrollTop } = viewport;

  const navbar = $("#header-navbar");
  if (scrollTop > 1) {
    navbar.addClass("is-scrolled");
  } else {
    navbar.removeClass("is-scrolled");
  }
};

function watchFooterVisibility() {
  const footerEl = document.querySelector(".website-footer");
  const contactUsBtnEl = $(".contact-us-btn-block");

  if (!footerEl) return;

  const isVisible = isInViewport(footerEl);

  if (isVisible) {
    contactUsBtnEl.fadeOut(0);
    contactUsBtnEl.attr("data-hidden", true);
  }

  if (contactUsBtnEl.data("hidden") && !isVisible) {
    contactUsBtnEl.fadeIn();
    contactUsBtnEl.attr("data-hidden", "");
  }
}

function watchHeroBlock() {
  const heroBlock = document.querySelector(".hero");
  const heroVideo = $(".hero__video");

  if (heroVideo) {
    if (!isInViewport(heroBlock)) {
      heroVideo.css({ position: "absolute" });
    } else {
      heroVideo.css({ position: "" });
    }
  }
}

export function initCustomScrollbar() {
  const isAlreadyCalled = [];
  handleHeaderClass();
  watchFooterVisibility();
  watchHeroBlock();

  function handleScrollEventMetrics(currentPercent) {
    const scrollPoints = [10, 50, 90];
    scrollPoints.forEach((point) => {
      if (currentPercent >= point && !isAlreadyCalled.includes(point)) {
        isAlreadyCalled.push(point);
        dataLayer.push({ event: "scrollPage", event_data: point });
      }
    });
  }

  if (window.CUSTOM_SCROLLBAR) {
    window.CUSTOM_SCROLLBAR.destroy();
  }

  window.CUSTOM_SCROLLBAR = OverlayScrollbars(
    document.querySelector(".main-container"),
    {
      overflow: {
        x: "hidden",
      },
    },
    {
      scroll(instance) {
        const { viewport } = instance.elements();
        const { overflowAmount } = instance.state();
        const { scrollTop } = viewport;
        const currentPercent = Math.floor(
          getPercantage(scrollTop, overflowAmount.y)
        );

        handleScrollEventMetrics(currentPercent);
        watchFooterVisibility();
        watchHeroBlock();
        handleHeaderClass(instance);
        handleParallax();
      },
    }
  );
}
