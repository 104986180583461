import KeenSlider from 'keen-slider';
import { NavigationPlugin } from '../../js/utils/swiper-plugins';

$(document).on('turbolinks:load', function () {
  const getVenuesCarouselId = (category_id) => {
    if (category_id) {
      return `venues-carousel-${category_id}`;
    }
    return `venues-carousel-featured`;
  };

  const createSlidesHtml = (data) => {
    return data
      .map((el) => {
        return `
        <div class="keen-slider__slide">
          <a href="/venues/${el.slug}" class="home-venue-card">
            ${el.image_link ? '<img src="' + el.image_link + '" alt="' + el.name + '" class="home-venue-card__image" />' : ''}
            <div class="home-venue-card__overlay"></div>
            <div class="home-venue-card__content">
              <div class="home-venue-card__title">${el.name}</div>
              <div class="home-venue-card__subtitle">${el.city_and_state}</div>
            </div>
          </a>
        </div>
        `;
      })
      .join('');
  };

  const createVenuesCarousel = (carousel_id) => {
    const carouselEl = document.getElementById(carousel_id);

    if (carouselEl && !$(carouselEl.parentNode).hasClass('n-section__body--loading')) {
      const carouselInstance = new KeenSlider(
        `#${carousel_id}`,
        {
          rubberband: false,
          mode: 'free',
          loop: false,
          slides: { perView: 'auto' },
          breakpoints: {
            '(min-width: 810px)': {
              rubberband: false,
              mode: 'free-snap',
              slides: { perView: 3, spacing: 24 },
            },
          },
          dragStarted: () => {
            dataLayer.push({ event: 'swipeVenueCarousel' });
          },
        },
        [NavigationPlugin],
      );

      return carouselInstance;
    }
  };

  const handleOnVenueTabLoad = (category_id, tabContainerEl) => {
    const venues_url = window.location.origin + '/venues/venues_by_general_category';
    const carouselId = getVenuesCarouselId(category_id);
    let removeLoading = false;

    function insertCarouselData(data) {
      const tabCarouselEl = document.getElementById(`${getVenuesCarouselId(category_id)}`);

      $(tabContainerEl).removeClass('n-section__body--loading');
      $(tabCarouselEl).html(createSlidesHtml(data));
      createVenuesCarousel(carouselId);
    }

    setTimeout(() => {
      removeLoading = true;

      if (VENUES_BY_CATEGORY_QUERIES[category_id]?.length) {
        insertCarouselData(VENUES_BY_CATEGORY_QUERIES[category_id]);
      }
    }, 1000);

    $.ajax({
      url: venues_url,
      type: 'GET',
      dataType: 'json',
      data: { category_id },
      statusCode: {
        200: function (data) {
          VENUES_BY_CATEGORY_QUERIES[category_id] = data;

          if (data?.length && removeLoading) {
            insertCarouselData(data);
          }
        },
      },
    });
  };

  const initVenuesCarouselTabs = () => {
    const CAROUSEL_ID = '#venues-tabs';

    if ($(CAROUSEL_ID).length) {
      new KeenSlider(
        CAROUSEL_ID,
        {
          rubberband: false,
          mode: 'free',
          slides: { perView: 'auto' },
        },
        [NavigationPlugin],
      );
    }

    $(CAROUSEL_ID).on('click', function (e) {
      const { venueCategory } = e.target.dataset || {};

      if (e.target.tagName === 'BUTTON') {
        // tabs style
        const sectionTabClassName = 'n-section-tabs__item';
        const tabs = $(`${CAROUSEL_ID}`).children();
        [...tabs].forEach((t) => {
          $(t).removeClass(`${sectionTabClassName}--active`);
        });

        $(e.target.parentNode).addClass(`${sectionTabClassName}--active`);
        if (venueCategory) {
          dataLayer.push({ event: 'switchToCatеgory', event_data: e.target.innerText });
        } else {
          dataLayer.push({ event: 'switchToFeaturedVenueTab' });
        }

        // tab content style
        const allTabContents = document.querySelectorAll('.home-venues > .n-section__body');

        [...allTabContents].forEach((t) => {
          $(t).hide();
        });

        const tabContainerEl = document.getElementById(`${getVenuesCarouselId(venueCategory)}-container`);

        $(tabContainerEl).fadeIn(300);

        if (venueCategory && !VENUES_BY_CATEGORY_QUERIES[venueCategory]) {
          handleOnVenueTabLoad(venueCategory, tabContainerEl);
        }
      }
    });
  };

  initVenuesCarouselTabs();
  createVenuesCarousel(getVenuesCarouselId());
});
