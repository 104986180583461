// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import '../css/application.scss';
require('./general');
window.VENUES_BY_CATEGORY_QUERIES = {};
window.UPCOMING_EVENT_QUERY = null;

require('./home/swipers');
require('./home/venues-block');
require('./home/events-block');
require('./home/image-parallax');


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
