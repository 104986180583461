import { OverlayScrollbars } from "overlayscrollbars";
import { debounce } from "../../../js/utils/debounce";
import { insertSearchCarouselData } from "./create-search-list";

const SEARCH_DOM_CLASSES = {
  SEARCH_CONTAINER: "n-header__search",
};

export const initSearch = (props) => {
  const {
    navbarId = "header-navbar",
    searchInputId = "header-search",
    dropdownId = "navbar-search-suggestions",
    onClose,
    onOpen,
  } = props || {};
  const searchInputQuery = `#${searchInputId}`;

  const onInputComplete = debounce((val) => {
    const search_url = window.location.origin + "/search/search.json";

    $.ajax({
      url: search_url,
      type: "GET",
      dataType: "json",
      data: { q: val },
      statusCode: {
        200: function (data) {
          insertSearchCarouselData(data, val);
          dataLayer.push({ event: "searching", event_data: val });
        },
      },
    });
  }, 400);

  $(searchInputQuery).on("focus", function (e) {
    window.preventAppScroll();

    if (onOpen) onOpen();
    $(`#${navbarId} .${SEARCH_DOM_CLASSES.SEARCH_CONTAINER}`).addClass(
      `${SEARCH_DOM_CLASSES.SEARCH_CONTAINER}--active`
    );
    $(`#${dropdownId}`).show().animate(
      {
        display: "block",
        opacity: 1,
        maxHeight: "420px",
      },
      400
    );

    OverlayScrollbars(
      document.querySelector(
        `#${dropdownId} .search-suggestions-scrollbar-container`
      ),
      {
        overflow: {
          x: "hidden",
        },
      }
    );

    dataLayer.push({ event: "focusOnSearchBar" });
  });

  $(searchInputQuery).on("input", function (e) {
    if (e.target.value.length > 1) {
      onInputComplete(e.target.value);
    } else {
      insertSearchCarouselData(JSON.parse(window.DEFAULT_SEARCH_RESULTS));
    }
  });

  $(document).on("click", function (e) {
    closeSearchDropdown(e);
  });

  function closeSearchDropdown(e) {
    if (
      !$(`#${navbarId} .${SEARCH_DOM_CLASSES.SEARCH_CONTAINER}--active`).length
    ) {
      return false;
    }

    const headerSearchContainer = document.querySelector(
      `.${SEARCH_DOM_CLASSES.SEARCH_CONTAINER}`
    );

    if (
      $.contains(headerSearchContainer, e.target) ||
      e.target.id === "header-search-mobile" ||
      e.target.id === "header-search"
    ) {
      return false;
    }

    $(`#${navbarId} .${SEARCH_DOM_CLASSES.SEARCH_CONTAINER}`).removeClass(
      `${SEARCH_DOM_CLASSES.SEARCH_CONTAINER}--active`
    );
    $(`#${dropdownId}`).animate(
      {
        opacity: 0,
        maxHeight: 0,
      },
      400,
      () => {
        if (onClose) onClose(e);
      }
    );
  }

  function forceCloseDropdown() {
    $(`#${navbarId} .${SEARCH_DOM_CLASSES.SEARCH_CONTAINER}`).removeClass(
      `${SEARCH_DOM_CLASSES.SEARCH_CONTAINER}--active`
    );
    $(`#${dropdownId}`).css({
      opacity: 0,
      maxHeight: 0,
    });

    if (onClose) onClose(null);
  }

  $(document).on("turbolinks:before-cache", function () {
    forceCloseDropdown();
  });
};
