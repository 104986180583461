require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

var jQuery = require("jquery");
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

require("../header/index");
require("./contact-form");

import { initCustomScrollbar } from "./init-scrollbar";
import { initMicroModal } from "./init-micromodal";

window.callGTMEvent = (eventName) => dataLayer.push({ event: eventName });
window.callGTMEventWithPath = (event, eventName) => {
  dataLayer.push({ event: eventName, event_link: event.currentTarget.href });
};
window.IS_EXPLORE_MENU_OPEN = false;
window.HEADER_UPCOMING_EVENT_QUERY =
  sessionStorage.getItem("HEADER_UPCOMING_EVENT_QUERY") || null;
window.DEFAULT_SEARCH_RESULTS =
  sessionStorage.getItem("DEFAULT_SEARCH_RESULTS") || null;
window.CUSTOM_SCROLLBAR = null;

window.openContactModal = (btnType) => {
  if (btnType && btnType === document.activeElement?.dataset?.gtmType) {
    dataLayer.push({
      event: "openContactUs",
      event_data: btnType,
    });
  }

  if (!btnType && !document.activeElement?.dataset?.gtmType) {
    dataLayer.push({
      event: "openContactUs",
      event_data: "general-button",
    });
  }

  if (btnType) {
    $('[data-micromodal-trigger="contact-modal"]').trigger("click");
  }
};

window.preventAppScroll = function () {
  $("body").addClass("overflow-hidden");
};

window.restoreAppScroll = function () {
  $("body").removeClass("overflow-hidden");
};

function setFixedVh() {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
}

$(document).on("turbolinks:load", function () {
  setFixedVh();
  initCustomScrollbar();
  initMicroModal();
});

window.addEventListener("resize", () => {
  setFixedVh();
});
