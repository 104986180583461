$(document).on("turbolinks:load", function () {
  const INPUT_VALIDATORS = {
    email: (val) => {
      const isEmailValid = (email) =>
        !!email.match(
          /^(|(([A-Za-z0-9]+_+)|(\+)|([A-Za-z0-9]+\-+)|([A-Za-z0-9]+\.+)|([A-Za-z0-9]+\++))*[A-Za-z0-9]+@((\w+\-+)|(\w+\.))*\w{1,63}\.[a-zA-Z]{2,6})$/i
        );

      return !val?.length
        ? "Field is required"
        : !isEmailValid(val)
          ? "Please enter an email in the format email@email.com"
          : "";
    },
    default: (val) => {
      return !val?.length ? "Field is required" : "";
    },
  };

  const validateHelper = (event) => {
    const { parentNode, value: val, dataset } = event.target || {};
    const { touched } = dataset || {};
    const currentValidator =
      INPUT_VALIDATORS[event.target.id?.replace("contact-", "")] ||
      INPUT_VALIDATORS.default;
    const error = currentValidator(val) || "";

    $(parentNode).attr("data-error", error);

    if (touched !== "false") {
      if (error) {
        if (touched === "blur") {
          $(parentNode).addClass("input-group__error");
        }
      } else {
        $(parentNode).removeClass("input-group__error");
      }
    }

    // Update submit button selector to work with Rails form
    const submitBtn = $("#contact-modal-form input[type='submit']")?.[0];

    if (!$("#contact-modal-form [data-touched='false']").length) {
      const isFormValid = !$("#contact-modal-form .input-group__error").length;
      if (isFormValid && submitBtn?.disabled) {
        $(submitBtn).prop("disabled", false);
      }

      if (!isFormValid && !submitBtn?.disabled) {
        $(submitBtn).prop("disabled", true);
      }
    }
  };

  $("#contact-phone").on("input", function () {
    let c = this.selectionStart;
    const regExp = /[^0-9-()\+\s]/gi;
    const extraSpaceRegExp = /\s{2,}/g;
    const value = $(this).val();
    if (regExp.test(value) || extraSpaceRegExp.test(value)) {
      $(this).val(value.replace(regExp, "").replace(extraSpaceRegExp, " "));
      c--;
    }

    this.setSelectionRange(c, c);
  });

  $("#contact-modal-form .n-form-control").on("blur", function (event) {
    $(this).attr("data-touched", "blur");
    validateHelper(event);
  });

  $("#contact-modal-form .n-form-control").on("input", function (event) {
    if (event.target.dataset.touched !== "blur") {
      $(this).attr("data-touched", "input");
    }

    validateHelper(event);
  });

  $("#contact-modal-form").on("submit", function (event) {
    event.preventDefault();

    const submit_url = window.location.origin + "/contact";
    const token = $('meta[name="csrf-token"]').attr("content");

    // Structure the data to match what the controller expects
    const formData = {
      contact: {
        name: $("#contact-name").val(),
        email: $("#contact-email").val(),
        phone: $("#contact-phone").val() || "",
        description: $("#contact-message").val(),
      },
      lead_source: "Contact form (Web)",
      custom_product: false,
    };

    $.ajax({
      type: "POST",
      url: submit_url,
      data: formData,
      dataType: "json",
      headers: {
        "X-CSRF-Token": token,
      },
      beforeSend: function () {
        $("#contact-modal-form input[type='submit']").prop("disabled", true);
        $(".contact-modal-form__general-error").html("");
      },
    })
      .done(function (data) {
        if (data.success) {
          $(".contact-modal__container--form").hide();
          $(".contact-modal__container--success").show();
          $("#contact-modal-form").attr("data-is-send", true);
          dataLayer.push({ event: "sendFeedback" });
          $("#contact-modal-form")[0].reset();
        } else {
          // Handle any logical errors that might be returned with success: false
          $(".contact-modal-form__general-error").html(
            data.errors?.join("<br>") || "An error occurred"
          );
        }
      })
      .fail(function (xhr) {
        let errorMessage =
          "An unexpected error occurred. Please try again later.";

        if (xhr.responseJSON && xhr.responseJSON.errors) {
          errorMessage = xhr.responseJSON.errors.join("<br>");
        }

        $(".contact-modal-form__general-error").html(errorMessage);
        $("#contact-modal-form").attr("data-is-send", false);
      })
      .always(function () {
        $("#contact-modal-form input[type='submit']").prop("disabled", false);
      });
  });
});
