require('./mobile-menu');
require('./explore-menu');
require('./search/index');

const handleHeaderClass = () => {
  const scrolled = $('.main-container').scrollTop();

  const navbar = $('#header-navbar');
  if (scrolled > 1) {
    navbar.addClass('is-scrolled');
  } else {
    navbar.removeClass('is-scrolled');
  }
};

$(document).on('turbolinks:load', function () {
  handleHeaderClass();

  $('.main-container').on('scroll', function () {
    handleHeaderClass();
  });
});
