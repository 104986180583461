import MicroModal from "micromodal";
import { isInViewport } from "../../js/utils/is-in-viewport";

export function initMicroModal() {
  MicroModal.init({
    disableScroll: true,
    onShow: function () {
      $(".contact-us-btn-block").fadeOut();
      window.preventAppScroll();
    },
    onClose: function () {
      window.restoreAppScroll();
      const footerEl = document.querySelector(".website-footer");
      if (footerEl && !isInViewport(footerEl)) {
        $(".contact-us-btn-block").fadeIn();
      }

      const contactModalForm = $("#contact-modal-form")?.[0];

      if (contactModalForm && contactModalForm.dataset.isSend === "true") {
        $(".contact-modal__container--success").hide();
        $(".contact-modal__container--form").show();
        $("#contact-modal-form").attr("data-is-send", false);
        contactModalForm.reset();
      }
    },
  });
}
