import KeenSlider from 'keen-slider';
import { NavigationPlugin } from '../../js/utils/swiper-plugins';

$(document).on('turbolinks:load', function () {
  const EVENT_TAB_TYPES = {
    FEATURED: 'featured',
    UPCOMING: 'upcoming',
  };

  const createEventsCarousel = (carousel_id) => {
    const carouselEl = document.getElementById(carousel_id);

    if (carouselEl && !$(carouselEl.parentNode).hasClass('n-section__body--loading')) {
      new KeenSlider(
        `#${carousel_id}`,
        {
          rubberband: false,
          mode: 'free',
          loop: false,
          slides: { perView: 'auto' },
          breakpoints: {
            '(min-width: 810px)': {
              rubberband: false,
              mode: 'free-snap',
              slides: { perView: 3, spacing: 24 },
            },
            '(min-width: 1200px)': {
              rubberband: false,
              mode: 'free-snap',
              slides: { perView: 4, spacing: 24 },
            },
          },
          dragStarted: () => {
            dataLayer.push({ event: 'swipeEventCarousel' });
          },
        },
        [NavigationPlugin],
      );
    }
  };

  const getEventsCarouselId = (tab_type) => {
    return `events-carousel-${tab_type}`;
  };

  const createSlidesHtml = (data) => {
    return data
      .map((el) => {
        return `
        <div class="keen-slider__slide">
          <a href="/events/${el.slug}" class="event-card">
            ${el.image_link ? '<img src="' + el.image_link + '" alt="' + el.title + '" class="event-card-image" />' : '<div class="event-card-image"></div>'}
            <div class="event-card-content">
              <div class="event-card-title">${el.title}</div>
              <div class="event-card-subtitle">${el.venue_name || ' '}</div>
              <div class="event-card-date">${el.day_month_year_full}</div>
              <div class="event-card-location">${el.city_and_state || ' '}</div>
            </div>
          </a>
        </div>
        `;
      })
      .join('');
  };

  const handleOnUpcomingTabLoad = (tabContainerEl) => {
    const events_url = window.location.origin + '/events/events_filled';
    const carouselId = getEventsCarouselId(EVENT_TAB_TYPES.UPCOMING);
    let removeLoading = false;

    function insertCarouselData(data) {
      const tabCarouselEl = document.getElementById(`${getEventsCarouselId(EVENT_TAB_TYPES.UPCOMING)}`);

      $(tabContainerEl).removeClass('n-section__body--loading');
      $(tabCarouselEl).html(createSlidesHtml(data));
      createEventsCarousel(carouselId);
    }

    setTimeout(() => {
      removeLoading = true;

      if (UPCOMING_EVENT_QUERY?.length) {
        insertCarouselData(UPCOMING_EVENT_QUERY);
      }
    }, 1000);

    $.ajax({
      url: events_url,
      type: 'GET',
      dataType: 'json',
      data: { is_featured: false, size: 8 },
      statusCode: {
        200: function (data) {
          UPCOMING_EVENT_QUERY = data;

          if (data?.length && removeLoading) {
            insertCarouselData(data);
          }
        },
      },
    });
  };

  const callEventsBlockGtm = (tabId) => {
    const eventMap = {
      upcoming: 'switchToUpcomingEventTab',
      featured: 'switchToFeaturedEventTab',
    };
    dataLayer.push({ event: eventMap[tabId] });
  };

  const initEventsCarouselTabs = () => {
    const CAROUSEL_ID = '#event-tabs';

    $(CAROUSEL_ID).on('click', function (e) {
      const { eventTab } = e.target.dataset || {};

      if (e.target.tagName === 'BUTTON') {
        // tabs style
        const sectionTabClassName = 'n-section-tabs__item';
        const tabs = $(`${CAROUSEL_ID}`).children();
        [...tabs].forEach((t) => {
          $(t).removeClass(`${sectionTabClassName}--active`);
        });

        $(e.target.parentNode).addClass(`${sectionTabClassName}--active`);

        // tab content style
        const allTabContents = document.querySelectorAll('.home-events > .n-section__body');

        [...allTabContents].forEach((t) => {
          $(t).hide();
        });

        callEventsBlockGtm(eventTab);

        const tabContainerEl = document.getElementById(`${getEventsCarouselId(eventTab)}-container`);

        $(tabContainerEl).fadeIn(300);

        if (eventTab === EVENT_TAB_TYPES.UPCOMING && !UPCOMING_EVENT_QUERY) {
          handleOnUpcomingTabLoad(tabContainerEl);
        }
      }
    });
  };

  initEventsCarouselTabs();
  createEventsCarousel(getEventsCarouselId(EVENT_TAB_TYPES.FEATURED));
});
